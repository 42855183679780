export enum EImportedFromMessage {
    WEB_INTERFACE = 'web_interface',
}

export const MAX_BUTTON_NAME_LENGTH = 32;

export const MAX_COUNT_ANSWER_POLL = 10;

export const MAX_LENGTH_QUESTION_POLL = 255;

export const MAX_LENGTH_ANSWER_POLL = 100;

export const MAX_LENGTH_SOLUTION_MESSAGE_POLL = 200;
