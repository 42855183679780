import {
    FC,
    memo,
} from 'react';

import { IArgsContentViewer } from 'features/content-viewer';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { TMessage } from 'shared/types/message';

import {
    IMessageOptions,
} from '../../model/types/message.types';

import { AlbumItem } from './AlbumItem';

import cls from './Album.module.scss';

interface IAlbumProps {
    message: TMessage;
    options: IMessageOptions;
    isWithoutTextAndOnlyMedia: boolean;
    openPreviewVideoOrPhoto: (props: IArgsContentViewer) => void;
}

export const Album: FC<IAlbumProps> = memo((props) => {
    const {
        isWithoutTextAndOnlyMedia, message, openPreviewVideoOrPhoto, options,
    } = props;

    const media = message?.post?.media;

    if (!media || media?.length === 0) {
        return null;
    }

    const wrapperClasses = {
        [cls.withoutTextAndOnlyMedia]: isWithoutTextAndOnlyMedia,
        [cls.documents]: options.withDocument,
        [cls.mediaImage]: options.withMediaPhotoVideo,
        [cls.webPreview]: options.isWebPreview,
    };

    return (
        <div
            className={classNames(
                cls.grid,
                wrapperClasses,
                [cls[`items${media?.length}`]],
            )}
        >
            {media?.map((mediaItem, i, array) => (
                <AlbumItem
                    array={array}
                    key={`${mediaItem.file.id}`}
                    index={i}
                    media={mediaItem}
                    message={message}
                    openPreviewVideoOrPhoto={openPreviewVideoOrPhoto}
                />
            ))}
        </div>
    );
});
