import { IStateSchema } from 'app/providers/store';

export const getCreateMessageDate = (state: IStateSchema) => state.createMessage.date;
export const getCreateMessageDocuments = (state: IStateSchema) => state.createMessage.documents;
export const getCreateMessageMedia = (state: IStateSchema) => state.createMessage.media;
export const getCreateMessageText = (state: IStateSchema) => state.createMessage.text;
export const getCreateMessageTime = (state: IStateSchema) => state.createMessage.time;
export const getCreateMessageTimezone = (state: IStateSchema) => state.createMessage.timezone;
export const getCreateMessageVariables = (state: IStateSchema) => state.createMessage.variables;
export const getCreateMessageButtons = (state: IStateSchema) => state.createMessage.buttons;
export const getCreateMessageMaxSymbolsLength = (state: IStateSchema) => state.createMessage.maxSymbolsLength;
export const getCreateMessageIsNotifyPin = (state: IStateSchema) => state.createMessage.isNotifyPin;
export const getCreateMessagePinDuration = (state: IStateSchema) => state.createMessage.pinDuration;
export const getCreateMessageIsPinIndefinitely = (state: IStateSchema) => state.createMessage.isPinIndefinitely;
export const getCreateMessageVoice = (state: IStateSchema) => state.createMessage.voice;
export const getCreateMessageIsWebPreview = (state: IStateSchema) => state.createMessage.isWebPreview;
export const getCreateMessageRoundVideo = (state: IStateSchema) => state.createMessage.roundVideo;
export const getCreateMessageIsDirty = (state: IStateSchema) => state.createMessage.isDirty;
export const getCreateMessageAudio = (state: IStateSchema) => state.createMessage.audio;
export const getCreateMessageDateValidType = (state: IStateSchema) => state.createMessage.dateValidType;
export const getCreateMessageCaptionOnTop = (state: IStateSchema) => state.createMessage.captionOnTop;
export const getCreateMessageAnswersPoll = (state: IStateSchema) => state.createMessage.answers;
export const getCreateMessageMultipleChoicePoll = (state: IStateSchema) => state.createMessage.multipleChoice;
export const getCreateMessageQuestionPoll = (state: IStateSchema) => state.createMessage.question;
export const getCreateMessageQuizPoll = (state: IStateSchema) => state.createMessage.quiz;
export const getCreateMessageSolutionIndexPoll = (state: IStateSchema) => state.createMessage.solutionIndex;
export const getCreateMessageIsNewAnswer = (state: IStateSchema) => state.createMessage.isNewAnswer;
export const getCreateMessageSolutionMessage = (state: IStateSchema) => state.createMessage.solutionMessage;
