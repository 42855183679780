import {
    FC,
    useCallback,
} from 'react';

import { classNames } from 'shared/lib/utils/classNames/classNames';
import {
    IPostPollAnswers,
    IPostPollData,
} from 'shared/types/message';
import { Text } from 'shared/ui/text/Text';

import cls from './PollMessage.module.scss';

interface IPollMessageProps {
    postPoll: IPostPollData;
}

export const PollMessage: FC<IPollMessageProps> = (props) => {
    const {
        postPoll,
    } = props;

    const answerList = postPoll.answers;

    const renderAnswer = useCallback((answer: IPostPollAnswers) => (
        <div className={cls.holderAnswer}>
            <div className={classNames(cls.typePollBlock, { [cls.typePollBlockMultiple]: postPoll.multipleChoice })} />
            <Text text={answer.value} size="md" theme="black" className={classNames('', {}, ['fontWithStickers'])} />
        </div>
    ), [postPoll]);

    return (
        <div className={cls.container}>
            <Text
                text={postPoll.question}
                size="md"
                theme="black-0"
                className={classNames(cls.question, {}, ['fontWithStickers'])}
            />
            <Text
                text={postPoll.quiz ? 'Анонимная викторина' : 'Анонимный опрос'}
                size="sm"
                theme="black-45"
                className={cls.typePoll}
            />

            <div className={cls.holderAnswerList}>
                {answerList.map(renderAnswer)}
            </div>
        </div>
    );
};
