import {
    Dispatch,
    FC,
    SetStateAction,
    Suspense,
    memo,
    useCallback,
    useMemo,
} from 'react';

import {
    Button,
    Modal,
} from 'antd';

import { EModalWidth } from 'shared/constants/modal-sizes';
import { IChannel } from 'shared/types/channels';
import { CustomUploadFile } from 'shared/types/file';
import { PageLoader } from 'shared/ui/page-loader/PageLoader';

import { CreateMessageContentAsync } from '../../content/create-message/CreateMessageContent.async';
import { SaveMessage } from '../../save-message/SaveMessage';

import cls from './CreateMessageModal.module.scss';

interface ICreateMessageModalProps {
    open: boolean;
    toggleModal: () => void;
    handleReloadChannels: () => void;
    setIsUpdateChannels: Dispatch<SetStateAction<boolean>>;
    localFileMediaList: CustomUploadFile[];
    setLocalFileMediaList: Dispatch<SetStateAction<CustomUploadFile[]>>;
    localFileAudioList: CustomUploadFile[];
    setLocalFileAudioList: Dispatch<SetStateAction<CustomUploadFile[]>>;
    channel: IChannel;
    handleCancelModal: () => void;
}

export const CreateMessageModal: FC<ICreateMessageModalProps> = memo((props) => {
    const {
        channel,
        handleCancelModal,
        handleReloadChannels,
        localFileAudioList,
        localFileMediaList,
        open,
        setIsUpdateChannels,
        setLocalFileAudioList,
        setLocalFileMediaList,
        toggleModal,
    } = props;

    const handleAfterAnimation = useCallback((isNotEnded: boolean) => {
        if (!isNotEnded) {
            setLocalFileAudioList([]);
            setLocalFileMediaList([]);
        }
    }, [setLocalFileAudioList, setLocalFileMediaList]);

    const footer = useMemo(
        () => [
            <Button key="back" onClick={toggleModal}>
                Отменить
            </Button>,
            <SaveMessage
                key="save"
                cancelModal={handleCancelModal}
                handleReloadChannels={handleReloadChannels}
                setIsUpdateChannels={setIsUpdateChannels}
                channel={channel}
            />,
        ],
        [
            handleCancelModal,
            handleReloadChannels,
            handleReloadChannels,
            channel,
        ],
    );

    return (
        <Modal
            open={open}
            centered
            title="Создать сообщение"
            onCancel={toggleModal}
            className={cls.editMessageModal}
            wrapClassName={cls.editMessageModalWrapper}
            footer={footer}
            destroyOnClose
            getContainer={false}
            width={EModalWidth.width700}
            afterOpenChange={handleAfterAnimation}
        >
            <Suspense fallback={<PageLoader isLoading withoutPortal classNameWithoutPortal={cls.loaderOnLoad} />}>
                <CreateMessageContentAsync
                    localFileMediaList={localFileMediaList}
                    setLocalFileMediaList={setLocalFileMediaList}
                    localFileAudioList={localFileAudioList}
                    setLocalFileAudioList={setLocalFileAudioList}
                />
            </Suspense>
        </Modal>
    );
});
