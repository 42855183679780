import {
    Dispatch,
    FC,
    MouseEvent,
    memo,
    useCallback,
} from 'react';

import { Tooltip } from 'antd';

import {
    VariablesModal,
} from 'entities/channels';
import VariableIcon from 'shared/assets/icons/variable.svg';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { IVariable } from 'shared/types/channels';
import {
    IIconTheme,
    WrappedIcon,
} from 'shared/ui/wrapped-icon/WrappedIcon';

import cls from './Controls.module.scss';

interface IVariablesControlProps {
    variables?: IVariable[];
    setIsVisibleVariablesModal: Dispatch<React.SetStateAction<boolean>>;
    isVisibleVariablesModal: boolean;
    onSelectVariable: (variables: IVariable) => void;
    classNameBtn?: string;
    themeIcon?: IIconTheme;
    disabled?: boolean;
}

export const VariablesControl: FC<IVariablesControlProps> = memo((props) => {
    const {
        classNameBtn, disabled = false, isVisibleVariablesModal, onSelectVariable, setIsVisibleVariablesModal,
        themeIcon = 'gray', variables,
    } = props;

    const handleCancel = useCallback(() => {
        setIsVisibleVariablesModal(false);
    }, []);

    const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setIsVisibleVariablesModal(true);
    };

    const handleAddVariable = (option: IVariable) => {
        onSelectVariable(option);
    };

    return (
        <>
            <Tooltip rootClassName={cls.hideTooltipMobile} title="Добавить переменную">
                <button
                    type="button"
                    className={classNames(cls.variableBtn, {}, [classNameBtn])}
                    onClick={handleOpen}
                    disabled={disabled}
                >
                    <WrappedIcon Icon={VariableIcon} theme={themeIcon} />
                </button>
            </Tooltip>

            <VariablesModal
                onClickVariable={handleAddVariable}
                isVisible={isVisibleVariablesModal}
                onCancel={handleCancel}
                variables={variables}
            />

        </>
    );
});
