import {
    FC,
    memo,
} from 'react';

import { classNames } from 'shared/lib/utils/classNames/classNames';
import { TMessage } from 'shared/types/message';

import {
    IMessageOptions,
} from '../../model/types/message.types';
import { AdditionalInfoMessage } from '../additionalInfo/AdditionalInfoMessage';

import cls from './Markup.module.scss';

interface IMarkupProps {
    message: TMessage;
    timezone?: string;
    options: IMessageOptions;
    markup: JSX.Element | null;
    isWithoutTextAndOnlyMedia: boolean;
    captionOnTop?: boolean;
}

export const Markup: FC<IMarkupProps> = memo((props) => {
    const {
        captionOnTop, isWithoutTextAndOnlyMedia, markup, message, options, timezone,
    } = props;

    if (!markup) {
        return (
            <AdditionalInfoMessage
                message={message}
                options={options}
                timezone={timezone}
                isWithoutTextAndOnlyMedia={isWithoutTextAndOnlyMedia}
                captionOnTop={captionOnTop}
            />
        );
    }

    return (
        <div
            className={classNames(cls.holderText, {
                [cls.holderTextWithMediaPhotoVideo]: options.withMediaPhotoVideo && !captionOnTop,
                [cls.holderTextWithMediaPhotoVideoOnTop]: options.withMediaPhotoVideo && captionOnTop,
            })}
        >
            <span
                className={classNames(
                    cls.text,
                    { [cls.textWebPreview]: options.isWebPreview },
                )}
            >
                {markup}
            </span>
            <AdditionalInfoMessage
                message={message}
                options={options}
                timezone={timezone}
                isWithoutTextAndOnlyMedia={isWithoutTextAndOnlyMedia}
                captionOnTop={captionOnTop}
            />
        </div>
    );
});
