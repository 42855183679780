import {
    FC,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';

import {
    messagesActions,
} from 'entities/message';
import MutedIcon from 'shared/assets/icons/mute.svg';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import useDeviceDetect from 'shared/lib/hooks/useDeviceDetect/useDeviceDetect';
import { useInitialVideoForAppleDevice } from 'shared/lib/hooks/useInitialVideoForAppleDevice/useInitialVideoForAppleDevice';
import useScreenType from 'shared/lib/hooks/useScreenType/useScreenType';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { IFile } from 'shared/types/message';
import { ImageWithLoader } from 'shared/ui/image-loader/ImageWithLoader';
import { OverlayFileLoading } from 'shared/ui/overlay-file-loading/OverlayFileLoading';
import { Skeleton } from 'shared/ui/skeleton/Skeleton';

import cls from './RoundVideo.module.scss';

interface IRoundVideoProps {
    className?: string;
    file: IFile;
    messageId: string;
}

export const RoundVideo: FC<IRoundVideoProps> = (props) => {
    const { className, file, messageId } = props;

    const dispatch = useAppDispatch();
    const { isTablet } = useScreenType();

    const [isMuted, setIsMuted] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const videoRef = useRef<HTMLVideoElement>(null);
    const { isAndroid, isIPad, isIPhone } = useDeviceDetect();

    const alwaysShow = isAndroid || isIPhone || isIPad;

    const hashFile = file.id && messageId ? `${messageId}/${file.id}` : null;

    useInitialVideoForAppleDevice({ setIsLoading, videoRef });

    const handleMouseLeave = () => {
        if (videoRef.current && !isLoading) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0.1;
            videoRef.current.muted = true;
            setIsMuted(true);
        }
    };

    const handleMouseEnter = async () => {
        if (isTablet) {
            return;
        }

        if (videoRef.current && !isLoading) {
            await videoRef.current.play();
        }
    };

    const handleToggleVideo = () => {
        if (!videoRef?.current) {
            return;
        }

        const isPlaying = !videoRef.current.paused;
        setIsMuted(isPlaying);
        videoRef.current.muted = isPlaying;

        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
    };

    const handleClickDesktopLaptopDevices = () => {
        if (!videoRef?.current) {
            return;
        }

        setIsMuted(false);
        videoRef.current.muted = false;
        videoRef.current.currentTime = 0;
        videoRef.current.play();
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (file.file) {
            e.stopPropagation();
        }

        if (videoRef.current && !isLoading) {
            dispatch(messagesActions.updatePlayingFileHashId(hashFile));

            if (isTablet) {
                handleToggleVideo();
                return;
            }

            handleClickDesktopLaptopDevices();
        }
    };

    useEffect(() => {
        const videoPlayer = videoRef.current;

        const initVideo = () => {
            setIsLoading(false);
        };

        if (videoPlayer) {
            videoPlayer.addEventListener('loadeddata', initVideo, false);
        }

        return () => {
            if (videoPlayer) {
                videoPlayer.removeEventListener('loadeddata', initVideo, false);
            }
        };
    }, []);

    const videoDisplay = (
        <>
            {isLoading && (
                <div className={cls.holderSkeleton}>
                    <Skeleton
                        className={classNames(cls.skeleton, {})}
                        width="270px"
                        height="270px"
                    />
                </div>
            )}

            <video
                src={file.file}
                ref={videoRef}
                muted
                controls={false}
                loop
                preload="metadata"
                playsInline
            />
        </>
    );

    const videoOrThumbnailDisplay = useMemo(() => {
        if (file?.file) {
            return videoDisplay;
        }
        return (
            <ImageWithLoader
                data-id={file.id}
                className={classNames(cls.imageWrapper, {}, [])}
                classNameImage={cls.imageItem}
                onContextMenu={(e) => e.preventDefault()}
                classNameSkeleton={cls.skeletonImage}
                src={file?.thumbnail || ''}
                alt={file?.name || 'image'}
            />
        );
    }, [videoDisplay, file]);

    return (
        <div
            className={classNames(cls.roundVideoContainer, {}, [className])}
            onClick={handleClick}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            role="presentation"
        >

            {videoOrThumbnailDisplay}

            {!file!.file && <OverlayFileLoading isAlwaysShow={alwaysShow} /> }

            {isMuted && !isLoading && !isTablet && (
                <div className={cls.volumeWrapper}>
                    <MutedIcon />
                </div>
            )}
        </div>
    );
};
