/* eslint-disable max-len */
import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useSelector } from 'react-redux';

import dayjs from 'dayjs';

import {
    IMessagesSchema,
    TUpdatingMessageStatuses,
    getMessagesFromSlice,
    messagesActions,
    getNextUrlMessage,
    getPreviousUrlMessage,
    IGroupedMessages,
    getScrollMessageId,
    getBehaviorScrollMessage,
    getIndexStartMes,
    getIsDisabledScroll,
} from 'entities/message';
import {
    IArgsContentViewer,
    contentViewerActions,
} from 'features/content-viewer';
import {
    HALF_PAGINATION_LIMIT,
    PAGINATION_LIMIT,
} from 'shared/constants/pagination';
import { EBehaviorScroll } from 'shared/constants/scroll';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useScroll } from 'shared/lib/hooks/useScroll/useScroll';
import { useScrollMessage } from 'shared/lib/hooks/useScrollMessage/useScrollMessage';
import { getSubArray } from 'shared/lib/utils/array/array';
import { getDayjsWithTimezonePlugin } from 'shared/lib/utils/date/date';
import { IChannel } from 'shared/types/channels';
import {
    EScrollTypes,
    TMessage,
} from 'shared/types/message';
import { IBackendListResponse } from 'shared/types/types';

interface IModalDeleteMessageSettings {
    messageId: TMessage['id'];
    open: boolean;
    importedFrom: string | null;
}

interface IUseMessageHandlerProps {
    channelId: IChannel['id'];
    messagesFromServer?: IBackendListResponse<TMessage>;
    isFetchingMessages: boolean;
    messageUpdatingStatus: TUpdatingMessageStatuses;
    reloadMessages: Function;
    setQueryParams: Dispatch<SetStateAction<string>>;
    setPrevChannelId: Dispatch<SetStateAction<string>>;
    prevChannelId: IChannel['id'];
    isLoadingMessages: boolean;
    shouldAddMessages: boolean;
    setShouldAddMessages: Dispatch<SetStateAction<boolean>>;
    channel?: IChannel;
    handleClearLastPublishedLoad: (value: boolean) => void;
    holderListElement: HTMLElement;
}
interface IUseMessageHandlerReturn {
    messages: TMessage[];
    setIsBottomScrolled: Dispatch<SetStateAction<boolean>>;
    handleCloseModalOnDelete: () => void;
    handleOpenModalOnDelete: (mesId: TMessage['id'], importedFrom: string | null) => void;
    deleteModalSettings: IModalDeleteMessageSettings;
    openPreviewVideoOrPhoto: (props: IArgsContentViewer) => void;
    groupedArray: IGroupedMessages[];
    isShowButtonScrollDown: boolean;
    handleScrollDown: () => void;
    disabledButtonScrollDown: boolean;
}

const MAX_DISPLAY_POSTS = PAGINATION_LIMIT * 2;
const TIME_CLEAR_DISABLE = 1000;
const TIME_CLEAR_SCROLL_CHANGE_INDEX = 300;

export const useMessageHandler = (props: IUseMessageHandlerProps): IUseMessageHandlerReturn => {
    const {
        channel,
        channelId,
        handleClearLastPublishedLoad,
        holderListElement,
        isFetchingMessages,
        isLoadingMessages,
        messageUpdatingStatus,
        messagesFromServer,
        prevChannelId,
        reloadMessages,
        setPrevChannelId,
        setQueryParams,
        setShouldAddMessages,
        shouldAddMessages,
    } = props;

    const dispatch = useAppDispatch();

    const messages = useSelector(getMessagesFromSlice.selectAll);
    const nextUrlMessage = useSelector(getNextUrlMessage);

    const previousUrlMessage = useSelector(getPreviousUrlMessage);
    const scrollMessageId = useSelector(getScrollMessageId);
    const behaviorScrollMessage = useSelector(getBehaviorScrollMessage);
    const isDisabledScrollGlobal = useSelector(getIsDisabledScroll);
    const indexStartMes = useSelector(getIndexStartMes);

    const { handleScrollDown } = useScrollMessage({ channelId, isAnimation: false });

    const [isBottomScrolled, setIsBottomScrolled] = useState(false);
    const [typeScroll, setTypeScroll] = useState<EScrollTypes>(EScrollTypes.TOP);
    const [deleteModalSettings, setIsOpenModalOnDelete] = useState<IModalDeleteMessageSettings>({ importedFrom: null, messageId: '', open: false });
    const [isScrollMessage, setIsScrollMessage] = useState(false);
    const [isScrollEndList, setIsScrollEndList] = useState(false);
    const [shouldScrollEndList, setShouldScrollEndList] = useState(false);
    const [isScrollBottom, setIsScrollBottom] = useState(false);
    const [isDisabledScrollState, setIsDisabledScrollState] = useState(false);
    const timeout = useRef<ReturnType<typeof setTimeout>>();
    const timeoutDisable = useRef<ReturnType<typeof setTimeout>>();
    const timeoutScrollChangeIndex = useRef<ReturnType<typeof setTimeout>>();

    const isScrollAfterChangeIndex = useRef<boolean>(false);
    const isScrollDownAfteUpdated = useRef<boolean>(false);

    const channelIdRef = useRef<string>();

    const isShowButtonScrollDown = useMemo(
        () => {
            const checkIndexScroll = (messages.length !== PAGINATION_LIMIT && messages.length - MAX_DISPLAY_POSTS > indexStartMes);

            return (!!previousUrlMessage || isScrollEndList || checkIndexScroll) && !isLoadingMessages;
        },
        [
            isScrollEndList,
            previousUrlMessage,
            messages,
            indexStartMes,
            channelId,
            isLoadingMessages,
        ],
    );

    const disabledButtonScrollDown = useMemo(
        () => (
            shouldScrollEndList
            || isLoadingMessages
            || isFetchingMessages
        ),
        [
            shouldScrollEndList,
            isLoadingMessages,
            isFetchingMessages,
        ],
    );

    const changeIndexStartMes = useCallback((index: number) => {
        dispatch(messagesActions.updateIndexStartMes(index));
    }, []);

    const groupedArray = useMemo(() => {
        const displayMessage = getSubArray(messages, indexStartMes, MAX_DISPLAY_POSTS);

        const groupedMessages: Record<string, TMessage[]> = displayMessage.reduce(
            (acc: Record<string, TMessage[]>, message: TMessage) => {
                getDayjsWithTimezonePlugin();

                const date = dayjs(message.publishAt).tz(channel?.timezone).format('YYYY-MM-DD');
                acc[date] = acc[date] ? [...acc[date], message] : [message];
                return acc;
            },
            {},
        );

        return Object.entries(groupedMessages).map(([publishAt, items]) => ({
            items,
            publishAt,
        }));
    }, [messages, indexStartMes, channel]);

    const handleShouldAddMessages = useCallback((url: string, isTop?: boolean) => {
        const nextPage = url.replace(/.*\?/gi, '');
        setQueryParams(nextPage);
        setShouldAddMessages(true);
        setTypeScroll(isTop ? EScrollTypes.TOP : EScrollTypes.BOTTOM);
    }, []);

    const handleLoadNewNextMessages = useCallback(() => {
        const isShouldAddNextMessages = nextUrlMessage && !isLoadingMessages && !shouldAddMessages && isBottomScrolled;

        if (isShouldAddNextMessages && !isDisabledScrollGlobal) {
            handleShouldAddMessages(nextUrlMessage, true);
        }
    }, [
        nextUrlMessage,
        isLoadingMessages,
        shouldAddMessages,
        isBottomScrolled,
        handleShouldAddMessages,
        isDisabledScrollGlobal,
    ]);

    const handleLoadPrevNextMessages = useCallback(() => {
        const isShouldAddPrevMessages = previousUrlMessage && !isLoadingMessages && !shouldAddMessages && isBottomScrolled;

        if (isShouldAddPrevMessages && !isDisabledScrollGlobal) {
            handleShouldAddMessages(previousUrlMessage);
        }
    }, [
        previousUrlMessage,
        isLoadingMessages,
        shouldAddMessages,
        isBottomScrolled,
        handleShouldAddMessages,
        isDisabledScrollGlobal,
    ]);

    const onAdjustScroll = useCallback(() => {
        dispatch(messagesActions.setScrollMessageId(null));
    }, []);

    const {
        changeScrollBottom,
        changeScrollTop,
        getIndexStartMessageScrollBottom,
        isDisabledScroll,
    } = useScroll<TMessage, IGroupedMessages>({
        displayListData: groupedArray,
        handleLoadNewNextMessages,
        handleLoadPrevNextMessages,
        holderListElement,
        indexStartMes,
        isDisabledScrollState: isDisabledScrollGlobal || isDisabledScrollState,
        listData: messages,
        onAdjustScroll,
        setIndexStartMes: changeIndexStartMes,
        setIsScrollEndList,
    });

    const handleScrollMessage = useCallback((behaviorScroll: EBehaviorScroll = EBehaviorScroll.SMOOTH) => {
        const elementToScrollTo = document.getElementById(scrollMessageId || '');
        elementToScrollTo?.scrollIntoView({ behavior: behaviorScroll, block: 'center' });
        isDisabledScroll.current = false;
        isScrollAfterChangeIndex.current = false;
        isScrollDownAfteUpdated.current = false;

        timeoutDisable.current = setTimeout(() => {
            dispatch(messagesActions.setIsDisabledScroll(false));
            setIsDisabledScrollState(false);
            clearTimeout(timeoutScrollChangeIndex.current);
        }, TIME_CLEAR_DISABLE);
    }, [scrollMessageId]);

    const changeNextOrPrevUrl = useCallback(
        ({
            next,
            previous,
        }: {
            next?: IMessagesSchema['nextUrlMessage'];
            previous?: IMessagesSchema['previousUrlMessage'];
        }) => {
            if (next !== undefined) {
                dispatch(messagesActions.updateNextUrlMessage(next));
            }
            if (previous !== undefined) {
                dispatch(messagesActions.updatePreviousUrlMessage(previous));
            }
        },
        [],
    );

    const handleCloseModalOnDelete = useCallback(() => {
        setIsOpenModalOnDelete({ importedFrom: null, messageId: '', open: false });
    }, []);

    const handleOpenModalOnDelete = useCallback((messageId: TMessage['id'], importedFrom?: string | null) => {
        setIsOpenModalOnDelete({ importedFrom: importedFrom || null, messageId, open: true });
    }, []);

    const openPreviewVideoOrPhoto = useCallback((propsArguments: IArgsContentViewer) => {
        const {
            contentType, coordinates, fileId, fileSrc,
            isOnLoad,
        } = propsArguments;

        dispatch(
            contentViewerActions.setContentViewer({
                contentType,
                coordinates,
                fileId,
                fileSrc,
                isOnLoad,
            }),
        );
    }, []);

    useEffect(() => {
        if (!isFetchingMessages && messageUpdatingStatus === 'updating' && messagesFromServer) {
            dispatch(messagesActions.setMessages(messagesFromServer.results));
            dispatch(messagesActions.setMessagesImportingStatus('updated'));
            dispatch(messagesActions.updateNextUrlMessage(messagesFromServer.next));
            dispatch(messagesActions.updatePreviousUrlMessage(messagesFromServer.previous));

            if (messagesFromServer.results.length > 0) {
                setIsScrollBottom(true);
            }
        }
        if (messageUpdatingStatus === 'shouldUpdate' && !isFetchingMessages) {
            setQueryParams('');
            reloadMessages();
            dispatch(messagesActions.setMessagesImportingStatus('updating'));
        }
    }, [messageUpdatingStatus, isFetchingMessages, messagesFromServer]);

    useEffect(() => {
        const isChannelChangedToAnother = channelIdRef.current !== channelId && messages.length === messagesFromServer?.results?.length;

        if (isChannelChangedToAnother && !isBottomScrolled) {
            channelIdRef.current = channelId;
            setTimeout(() => {
                setIsBottomScrolled(true);
            }, 100);
        }
    }, [messages, isBottomScrolled]);

    useEffect(() => {
        const isWeShouldAddAdditionalMessages = messagesFromServer
        && messagesFromServer.results.length > 0
        && shouldAddMessages
        && !isFetchingMessages
        && !isDisabledScrollGlobal;

        const isShouldNotAddMessages = messagesFromServer
        && messagesFromServer.results.length === 0
        && shouldAddMessages
        && !isFetchingMessages
        && !isDisabledScrollGlobal;

        if (isWeShouldAddAdditionalMessages) {
            dispatch(messagesActions.setIsMessageListUpdate(false));
            dispatch(messagesActions.setScrollMessageId(null));

            const addMessages = () => {
                if (typeScroll === EScrollTypes.BOTTOM) {
                    const messageLastIndex = messages.length + messagesFromServer.results.length;
                    const isChangeIndexStartMes = indexStartMes < messageLastIndex - MAX_DISPLAY_POSTS;
                    if (isChangeIndexStartMes) {
                        const index = getIndexStartMessageScrollBottom(messageLastIndex);
                        changeIndexStartMes(index);
                    }
                }
                dispatch(messagesActions.addMessages(messagesFromServer.results));

                changeNextOrPrevUrl({
                    next: typeScroll === EScrollTypes.TOP ? messagesFromServer.next : undefined,
                    previous: typeScroll === EScrollTypes.BOTTOM ? messagesFromServer.previous : undefined,
                });
                setShouldAddMessages(false);
            };

            const changeScrollPositionAddingNewPosts = (lengthNewPosts: number) => {
                if (typeScroll === EScrollTypes.TOP) {
                    changeScrollTop(lengthNewPosts);
                } else {
                    changeScrollBottom(lengthNewPosts);
                }
            };

            addMessages();
            changeScrollPositionAddingNewPosts(messagesFromServer.results.length);
        }

        if (isShouldNotAddMessages) {
            setShouldAddMessages(false);
        }
    }, [messagesFromServer, shouldAddMessages, isFetchingMessages]);

    useEffect(() => {
        if (channelId !== prevChannelId && !isFetchingMessages && messagesFromServer) {
            setIsBottomScrolled(false);
            dispatch(messagesActions.setMessages(messagesFromServer.results));
            dispatch(messagesActions.updateNextUrlMessage(messagesFromServer.next));
            dispatch(messagesActions.updatePreviousUrlMessage(messagesFromServer.previous));

            setPrevChannelId(channelId);
            setQueryParams('');
            setIsScrollEndList(!!messagesFromServer.previous);

            if (messagesFromServer.results.length > PAGINATION_LIMIT) {
                changeIndexStartMes(messagesFromServer.results.length - PAGINATION_LIMIT);
            }

            setIsScrollMessage(true);
        }
    }, [channelId, messagesFromServer, isFetchingMessages]);

    useEffect(() => {
        if (shouldScrollEndList && !isFetchingMessages && messagesFromServer) {
            setShouldScrollEndList(false);
            dispatch(messagesActions.setMessages(messagesFromServer.results));
            dispatch(messagesActions.updateNextUrlMessage(messagesFromServer.next));
            dispatch(messagesActions.updatePreviousUrlMessage(messagesFromServer.previous));

            isDisabledScroll.current = true;
            isScrollDownAfteUpdated.current = true;
        }
    }, [shouldScrollEndList, messagesFromServer, isFetchingMessages]);

    useEffect(() => {
        if (isScrollMessage && channel && channelId === channel.id) {
            const hasLastPublishedPost = !!channel?.lastPublishedPostId && !!channel?.lastPublishedPostPageUrl;

            handleClearLastPublishedLoad(hasLastPublishedPost);

            if (messages.length > 0) {
                setIsScrollBottom(!hasLastPublishedPost);
            }

            setIsScrollMessage(false);
        }
    }, [channel, isScrollMessage, channelId]);

    useEffect(() => {
        // Данная логика нам нужна, для прокрутки нашего списка к определенному элементу
        if (scrollMessageId !== null) {
            const index = messages.findIndex((item) => item.id.toString() === scrollMessageId?.toString());

            isDisabledScroll.current = true;
            setIsDisabledScrollState(true);
            clearTimeout(timeout.current);
            clearTimeout(timeoutDisable.current);
            setShouldAddMessages(false);
            isScrollAfterChangeIndex.current = false;
            clearTimeout(timeoutScrollChangeIndex.current);

            if (index === -1) {
                isDisabledScroll.current = false;
                dispatch(messagesActions.setScrollMessageId(null));
                timeoutDisable.current = setTimeout(() => {
                    dispatch(messagesActions.setIsDisabledScroll(false));
                    setIsDisabledScrollState(false);
                }, TIME_CLEAR_DISABLE);
                return;
            }

            const indexEndList = indexStartMes + MAX_DISPLAY_POSTS;
            const isChangeStartMesIndex = indexStartMes > index || index > indexEndList;

            if (isChangeStartMesIndex) {
                // Если мы меняем индекс отображаемых постов, то мы должны дождаться пока переменная groupedArray
                // высчитается и только после скролиться к сообщению
                changeIndexStartMes(Math.max(0, index - HALF_PAGINATION_LIMIT + 1));
                isScrollAfterChangeIndex.current = true;
            } else {
                handleScrollMessage(behaviorScrollMessage);
            }
        }
    }, [scrollMessageId]);

    useEffect(() => {
        if (isScrollAfterChangeIndex.current) {
            isScrollAfterChangeIndex.current = false;
            timeoutScrollChangeIndex.current = setTimeout(
                () => handleScrollMessage(behaviorScrollMessage),
                TIME_CLEAR_SCROLL_CHANGE_INDEX,
            );
        }
    }, [groupedArray]);

    useEffect(() => {
        if (isScrollBottom) {
            handleScrollDown(EBehaviorScroll.AUTO);
            setIsScrollBottom(false);
        }
    }, [isScrollBottom]);

    return {
        deleteModalSettings,
        disabledButtonScrollDown,
        groupedArray,
        handleCloseModalOnDelete,
        handleOpenModalOnDelete,
        handleScrollDown,
        isShowButtonScrollDown,
        messages,
        openPreviewVideoOrPhoto,
        setIsBottomScrolled,
    };
};
