import {
    FC,
    useMemo,
    Dispatch,
    SetStateAction,
    memo,
    useCallback,
} from 'react';

import {
    Button,
    Modal,
    UploadFile,
} from 'antd';

import { EModalWidth } from 'shared/constants/modal-sizes';
import { CustomUploadFile } from 'shared/types/file';
import { Flex } from 'shared/ui/flex/Flex';
import { Text } from 'shared/ui/text/Text';

import cls from './WebPreviewWarningModal.module.scss';

interface IWebPreviewWarningModalProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
    handleChangeFileList: (newFileList: UploadFile[]) => void;
    tempFileList: CustomUploadFile[];
    setTempFileList: Dispatch<SetStateAction<CustomUploadFile[]>>;
    handleAccept: () => void;
}

export const WebPreviewWarningModal: FC<IWebPreviewWarningModalProps> = memo((props) => {
    const {
        handleAccept, handleChangeFileList, isOpen, setIsOpen, setTempFileList, tempFileList,
    } = props;

    const handleOk = useCallback(() => {
        handleChangeFileList(tempFileList);
        setIsOpen(false);
        handleAccept();
    }, [handleChangeFileList, tempFileList, setIsOpen]);

    const handleCancel = useCallback(() => {
        setTempFileList([]);
        setIsOpen(false);
    }, [setTempFileList, setIsOpen]);

    const footer = useMemo(() => (
        <Flex max>
            <Button key="back" block onClick={handleCancel}>
                Отменить
            </Button>
            <Button
                type="primary"
                key="apply"
                block
                onClick={handleOk}
            >
                Добавить фото
            </Button>
        </Flex>

    ), [handleOk, handleCancel]);

    return (
        <Modal
            open={isOpen}
            closeIcon={false}
            width={EModalWidth.width400}
            footer={footer}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Text
                isTitle
                size="lg"
                align="center"
                theme="black"
                weight="600"
                text="Обратите внимание"
            />
            <Text
                className={cls.webPreviewText}
                text={(
                    <>
                        Вы используете изображение в формате
                        {' '}
                        <span className={cls.webPreviewLink}>web preview</span>
                        , при добавлении
                        более одного изображения тип вложений изменится на альбом и ограничение по тексту составит 1024 символов.
                    </>
                )}
            />
        </Modal>
    );
});
