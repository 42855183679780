import {
    Dispatch,
    RefObject,
    useEffect,
    useRef,
    useState,
} from 'react';

import { useOverlayScrollbars } from 'overlayscrollbars-react';

interface IReturnUseCustomStyleScrollProps {
    customRootRef?: RefObject<HTMLDivElement>;
}

interface IReturnUseCustomStyleScrollReturn {
    rootRef: RefObject<HTMLDivElement>;
    setScroller: Dispatch<React.SetStateAction<HTMLElement | null>>;
    scroller: HTMLElement | null;
}

export const useCustomStyleScroll = (props: IReturnUseCustomStyleScrollProps): IReturnUseCustomStyleScrollReturn => {
    const { customRootRef } = props;

    const rootRef = useRef<HTMLDivElement>(null);
    const [scroller, setScroller] = useState<HTMLElement | null>(null);
    const [initialize, osInstance] = useOverlayScrollbars({
        defer: true,
        options: {
            scrollbars: {
                autoHide: 'leave',
                autoHideDelay: 200,
            },
        },
    });

    useEffect(() => {
        const { current: root } = customRootRef || rootRef;
        if (scroller && root) {
            initialize({
                elements: {
                    viewport: scroller,
                },
                target: root,
            });
        }
        return () => osInstance()?.destroy();
    }, [scroller, initialize, osInstance]);

    return {
        rootRef,
        scroller,
        setScroller,
    };
};
