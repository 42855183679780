import {
    FC,
    memo,
    useCallback,
} from 'react';

import { IVariable } from 'shared/types/channels';
import { IMessageButton } from 'shared/types/message';
import { Flex } from 'shared/ui/flex/Flex';

import { MessageButtonItem } from './MessageButtonItem';

import cls from './MessageButtonList.module.scss';

interface IMessageButtonListProps {
    buttonList?: IMessageButton[];
    variables: IVariable[];
}

export const MessageButtonList: FC<IMessageButtonListProps> = memo(({ buttonList, variables }) => {
    const renderButton = useCallback(
        (buttonItem: IMessageButton) => <MessageButtonItem key={buttonItem.id} buttonItem={buttonItem} variables={variables} />,
        [variables, buttonList],
    );

    if (!buttonList || buttonList.length === 0) {
        return null;
    }

    return (
        <Flex direction="column" className={cls.container} onContextMenu={(e) => e.preventDefault()}>
            {buttonList?.map(renderButton)}
        </Flex>
    );
});
