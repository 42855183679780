import {
    FC,
    memo,
    useCallback,
    useMemo,
} from 'react';

import {

    messagesActions,
} from 'entities/message';
import { useAppDispatch } from 'shared/lib/hooks/useAppDispatch/useAppDispatch';
import { useScrollMessage } from 'shared/lib/hooks/useScrollMessage/useScrollMessage';
import { classNames } from 'shared/lib/utils/classNames/classNames';
import { replaceTagsWithVariables } from 'shared/lib/utils/deserialize/deserialize';
import {
    IChannel,
    IVariable,
} from 'shared/types/channels';
import {
    EMediaMessageTypes,
    EPostTypeMessage,
    TMessage,
} from 'shared/types/message';
import { IBackendListResponse } from 'shared/types/types';
import { MiniHeader } from 'shared/ui/mini-header/MiniHeader';
import { Text } from 'shared/ui/text/Text';

import cls from './PinnedMessageList.module.scss';

interface IPinnedMessageListProps {
    pinMessage: TMessage;
    pinMessagePage: string;
    className?: string;
    messagesFromServer?: IBackendListResponse<TMessage>;
    variables: IVariable[];
    channelId: IChannel['id'];
}

const renderVideo = (file: string) => (
    <div className={cls.imgWrapper}>
        <video src={file} controls={false} className={cls.video} />
    </div>
);

export const PinnedMessageList: FC<IPinnedMessageListProps> = memo(({
    channelId,
    className,
    pinMessage,
    pinMessagePage,
    variables,
}) => {
    const dispatch = useAppDispatch();

    const { handleShowMessage } = useScrollMessage({
        channelId,
    });

    const textPinMessage = useMemo(() => {
        const typeMessage = pinMessage.postType;
        const firstMedia = pinMessage?.post?.media?.[0];
        const isFirstMediaDocument = firstMedia?.type === EMediaMessageTypes.DOCUMENT;
        const text = pinMessage?.text;

        if (typeMessage === EPostTypeMessage.ROUND_VIDEO) {
            return 'Видео-сообщение';
        }
        if (typeMessage === EPostTypeMessage.AUDIO_MESSAGE) {
            return 'Аудио-сообщение';
        }
        if (typeMessage === EPostTypeMessage.VOICE_MESSAGE) {
            return 'Голосовое сообщение';
        }
        if (
            typeMessage === EPostTypeMessage.ANIMATED_STICKER
            || typeMessage === EPostTypeMessage.VIDEO_STICKER
            || typeMessage === EPostTypeMessage.STATIC_STICKER
        ) {
            return 'Стикер';
        }
        if (isFirstMediaDocument && !text) {
            return firstMedia?.file?.name;
        }

        return replaceTagsWithVariables(text, variables);
    }, [pinMessage, variables]);

    const File = useMemo(() => {
        if (pinMessage.postType === EPostTypeMessage.ALBUM) {
            const { file: dataFile, type } = pinMessage?.post?.media?.[0] || {};
            if (type === EMediaMessageTypes.PHOTO && dataFile) {
                return (
                    <div className={cls.imgWrapper}>
                        <img src={dataFile.file} alt="prev" className={cls.img} />
                    </div>
                );
            }
            if (type === EMediaMessageTypes.VIDEO && dataFile) {
                return renderVideo(dataFile.file);
            }
        }

        if (pinMessage.postType === EPostTypeMessage.ROUND_VIDEO) {
            const file = pinMessage?.post?.file?.file || '';
            return renderVideo(file);
        }

        return null;
    }, [pinMessage]);

    const onShowMessage = useCallback(() => {
        dispatch(messagesActions.setScrollMessageId(null));

        setTimeout(() => {
            handleShowMessage(pinMessage.id, pinMessagePage);
        }, 0);
    }, [handleShowMessage, pinMessage, pinMessagePage]);

    return (
        <MiniHeader className={classNames(cls.pinnedMessageList, {}, [className])}>
            <button type="button" className={cls.wrapper} onClick={onShowMessage}>
                <div className={cls.lineList}>
                    <div className={classNames(cls.line)} />
                </div>
                {File}
                <div className={cls.infoMessage}>
                    <Text text="Последнее закрепленное сообщение" theme="link" align="left" />
                    <Text
                        text={textPinMessage}
                        theme="gray"
                        className={classNames(cls.infoMessageText, {}, ['fontWithStickers'])}
                    />
                </div>
            </button>
        </MiniHeader>
    );
});
